<template>
  <div class="seckill_I">
    <div class="headNavs">
      <div class="headTilte">秒杀商品</div>
      <div class="headDetails">说明：秒杀商品</div>
    </div>
    <div class="capitalNav">
      <div style="display:flex;justify-content: space-between;">
        <div class="NavLi">
          <div class="NavLiName">商品分类：</div>
          <div class="NavLiIpt">
            <el-cascader
              ref="Casc"
              style="width:217px"
              v-model="Row_piii2"
              :options="CateList"
              :change-on-select="true"
              :props="{ value:'id', label:'name'}"
              @change="handleChange2"></el-cascader>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">名称：</div>
          <div class="NavLiIpt">
            <el-input v-model="search.goods_name" placeholder="搜索名称" style="width: 108%;"></el-input>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">站点：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.site_id" placeholder="全部">
              <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">商品规格：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.spec_type" placeholder="全部">
              <el-option v-for="item in TypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="NavLi"></div>
        <div class="NavLi"></div>
      </div>
      <div style="margin-top:20px">
        <div class="NavLi">
          <div class="NavLiName">商品状态：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.goods_status" placeholder="全部">
              <el-option v-for="item in Goods_staus" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="NavLiQuery" style="margin-left: 24px;" @click="BtnSearch()">查询</div>
          <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
        </div>
      </div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入商品简称">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="miaoshaGoods"
        class="table"
        ref="site_id"
        header-cell-class-name="table-header">
        <el-table-column prop="goods_id" label="ID"  align="center" width="50"></el-table-column>
        <el-table-column prop="" label="站点"  align="center"  width="70">
            <template #default="scope">
              <span v-for="(item,key) in scope.row.site" :key="key">{{item}},</span>
            </template>
        </el-table-column>
        <el-table-column prop="goods_short_name" label="商品简称"  align="center"></el-table-column>
        <el-table-column prop="category.name" label="商品分类"  align="center"> </el-table-column>
        <el-table-column prop="wap_image" label="商品图片"  align="center">
          <template #default="scope">
              <el-image class="Toper"
                  :src="scope.row.full_images" alt=""
                  :preview-src-list="[scope.row.full_images]"
                  :initial-index="0"
                  :hide-on-click-modal = true
                  fit="cover">  
              </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="sale_type_text" label="销售类型"  align="center"> </el-table-column>
        <el-table-column prop="goods_type_text" label="商品类型"  align="center"> </el-table-column>
        <el-table-column prop="spec_type_text" label="商品规格"  align="center"> </el-table-column> 
        <el-table-column prop="sales_initial" label="实际销量"  align="center"> </el-table-column>
        <el-table-column prop="goods_sort" label="权重" align="center" ></el-table-column>
        <el-table-column prop="goods_status_text" label="商品状态"  align="center"> </el-table-column>
        <el-table-column prop="is_sale" label="销售状态"  align="center" width="100">
            <template  #default="scope">
              <span>{{ {0:'在售',1:'已售完'}[scope.row.is_sale||0] }}</span>
          </template>
        </el-table-column> 
        <el-table-column label="操作" align="center" width="140">
          <template #default="scope">
            <div class="el_butto" style="margin:.3125rem .625rem .3125rem 0">
              <el-button
                  type="text"
                  @click="HandleEdit(scope.$index,scope.row)"
              >编辑
              </el-button>
            </div>
            <div class="el_butto rere" style="margin:.3125rem .625rem .3125rem 0">
              <el-button
                  type="text"
                  class="red"
                  @click="HandleDel(scope.$index, scope.row)"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="miaototal">
        </el-pagination>
      </div>
    </div>

    <div class="show" v-if="ruleForm2">
      <div class="rightPanel-background"/>
      <div class="EditNew" style="width:900px">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <div class="headNavs" style="padding:0 26px">
          <el-menu
            :default-active="acndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect2">
            <el-menu-item index="1">商品信息</el-menu-item>
            <el-menu-item index="2">商品详情</el-menu-item>
            <el-menu-item index="3">会员折扣</el-menu-item>
          </el-menu>
        </div>
        <el-form :model="ruleFormRef " :rules="rules" ref="ruleFormRef" >
          <div class="EditNewForm" v-show="info_Show == 0">
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName mation">一、基础信息</div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube" prop="Row.Row_piii">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>商品分类</div>
              <div class="EditNewFormItemIpt">
                <el-cascader
                  ref="Casc"
                  style="width:100%;"
                  v-model="ruleFormRef.Row.Row_piii"
                  :options="CateList"
                  :change-on-select="true"
                  :props="{ value:'id', label:'name'}"
                  @change="handleChange"></el-cascader>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube" prop="Row.goods_short_name">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>商品简称</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="ruleFormRef.Row.goods_short_name" placeholder="请输入商品简称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube" prop="Row.goods_name">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>商品全称</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="ruleFormRef.Row.goods_name" placeholder="请输入商品全称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns" prop="Row.goods_intro">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>商品简介</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="ruleFormRef.Row.goods_intro" placeholder="请输入商品全称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns" prop="Row.label_ids">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>商品标签 (可多选)</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="ruleFormRef.Row.label_ids" multiple placeholder="请选择">
                  <el-option v-for="item in LabelList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns" prop="Row.site_ids">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>站点</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="ruleFormRef.Row.site_ids" multiple placeholder="全部">
                  <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName mation">二、销售设置</div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns" prop="Row.spec_type">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>商品规格</div>
              <div class="EditNewFormItemIpt" style="width:90%">
                <el-select v-model="ruleFormRef.Row.spec_type" placeholder="请选择商品规格" @change="Sku_Chang" style="width:33%">
                  <el-option v-for="(item,key) in StatisticsColumns.spec_type" :key="key" :label="item" :value="key"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <div v-if="Sku == 1 || Sku == 2 || Sku == 3 || Sku == 4 " style="width:100%">
              <div class="Sku_ADD" style="margin-bottom:25px" v-if="Sku != 4 ">
                <el-button @click="Sku_Chang('add')" type="primary">添加规格</el-button>
              </div>
              <div class="Sku_boder"  v-if="Sku == 2 || Sku == 3 || Sku == 4 ">
                <div v-if="Sku == 3">
                  <div  v-for="(item, index) in Spec_many_title" :key="index">
                    <div class="Rig_D ">
                      <div class="Rig_Ddd" style="margin-top:7px;width:70px">规格名</div>
                      <div class="Sku_name" @click="imgClick(index)">
                        <span>{{item.group_name}}</span><img style="margin-left:0;float:right" class="Img_ti" src="../../assets/img/del_p.png" alt="">
                      </div>
                    </div>
                    <!-- 添加规格值 -->
                    <div class="Rig_D ">
                      <div class="Rig_Ddd" style="margin-top:7px;width:70px">规格值</div>
                      <div  v-for="items in item.spec_items" :key="items">
                        <span class="Sku_zhi">{{items.spec_value}}</span>       
                      </div>
                      <div class="Sku_inpiua">
                        <el-input type="tel" v-model.trim ="spec_ad" placeholder="请输入规格值"  style="margin-top:-3px" ></el-input>
                        <span class="Sku_sppo" @click="Sku_ADDSpace(spec_ad, item ,index)">添加</span>
                      </div>
                    </div>                      
                  </div>
                </div>
                <!-- 添加规格名 值 -->
                <div  v-if="Sku  == 4 || Sku_e  == 'ad'">
                  <div class="Rig_D ">
                    <div class="Rig_Ddd" style="margin-top:7px;width:70px">规格名</div>
                    <div class="Rtty Righhh">
                      <el-input type="tel" v-model="SPEC.spec_name" placeholder="请输入规格名称" style="width:30%"></el-input>
                    </div>
                  </div>
                  <div class="Rig_D " style="margin-top:15px">
                    <div class="Rig_Ddd" style="margin-top:7px;width:70px">规格值</div>
                    <div class="Rtty Righhh">
                      <el-input type="tel" v-model="SPEC.spec_value" placeholder="请输入规格值" style="width:30%"></el-input>
                    </div>
                  </div>
                  <div style="margin: 30px 10px;">
                    <el-button @click="Sku_ADD()" type="primary">确定</el-button>
                    <el-button @click="Sku_ADD(false)">取消</el-button>
                  </div>
                </div>
                <div v-if="Sku == 3 " style="border-bottom:2px  dashed  #eee;"> </div> 
                <div v-if="Sku == 3 " style="margin-top:20px">
                  <el-table
                    border
                    style="width: 100%;"  
                    :data="Spec_many"
                    :span-method="tableMerge"
                  >
                    <el-table-column 
                      v-for="(item , index) in Spec_many_title"
                      :key="index"
                      :label="item.group_name"
                      align="center"
                    >
                      <template #default="scope">
                        <span>{{scope.row.rows[index].spec_value}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="tuan_price" label="活动价" align="center">
                      <template #default="scope">
                        <input type="number" v-model="scope.row.form.tuan_price" class="Price">
                      </template>
                    </el-table-column>
                    <el-table-column prop="goods_price" label="销售价" align="center">
                      <template #default="scope">
                        <input type="number" v-model="scope.row.form.goods_price" class="Price">
                      </template>
                    </el-table-column>
                    <el-table-column prop="line_price" label="划线价" align="center" >
                      <template #default="scope">
                        <input type="number" v-model="scope.row.form.line_price" class="Price">
                      </template>
                    </el-table-column>
                   </el-table>
                </div>
              </div>
            </div>
            <el-form-item class="EditNewFormItemTube" prop="Spec.goods_price" v-if="Sku == 0">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>商品销售价格</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number" v-model="ruleFormRef.Spec.goods_price" placeholder="请输入商品简称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube" prop="Spec.line_price" v-if="Sku == 0">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>商品活动价</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number" v-model="ruleFormRef.Spec.line_price" placeholder="请输入商品简称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube" prop="Spec.tuan_price" v-if="Sku == 0">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>商品划线价</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number" v-model="ruleFormRef.Spec.tuan_price" placeholder="请输入商品简称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube" prop="Row.sale_type">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>销售类型</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="ruleFormRef.Row.sale_type" placeholder="请选择销售类型" >
                  <el-option v-for="(item,key) in StatisticsColumns.sale_type" :key="key" :label="item" :value="key"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube" prop="Row.goods_status">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>商品状态</div>
              <div class="EditNewFormItemIpt">
               <el-select v-model="ruleFormRef.Row.goods_status" placeholder="请选择商品状态">
                  <el-option v-for="(item,key) in StatisticsColumns.goods_status" :key="key" :label="item" :value="key"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube" prop="Row.is_sale">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>销售状态</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="ruleFormRef.Row.is_sale" placeholder="请选择销售状态" style="width:100%;" >
                  <el-option v-for="(item,key) in StatisticsColumns.is_sale" :key="key" :label="item" :value="key"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube" prop="sales_initial">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>初始销量</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number" v-model="ruleFormRef.Row.sales_initial" required placeholder="请输入虚拟销量"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube" prop="start_time">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>秒杀开始时间</div>
              <div class="EditNewFormItemIpt">
                <el-date-picker v-model="Miaosha.start_time" required  type="datetime" placeholder="请选择秒杀开始时间" />
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube" prop="end_time">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>秒杀结束时间</div>
              <div class="EditNewFormItemIpt">
                <el-date-picker v-model="Miaosha.end_time" required  type="datetime" placeholder="请选择秒杀结束时间" />
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube" prop="total_num">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>秒杀总库存</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number" v-model="Miaosha.total_num" required placeholder="请输入秒杀总库存" class="El_ii"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube" prop="min_buy_num">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>最低购买数量</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number" v-model="Miaosha.min_buy_num" required placeholder="请输入最低购买数量" class="El_ii"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube" prop="max_buy_num">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>最多购买数量</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number" v-model="Miaosha.max_buy_num" required placeholder="请输入最多购买数量" class="El_ii"></el-input>
              </div>
            </el-form-item>
            <div style="display:flex;width: 100%;">
              <el-form-item class="EditNewFormItemTube">
                <div class="EditNewFormItemName"><span class="signSpan">* </span>已秒杀数量</div>
                <div class="EditNewFormItemIpt">
                  <el-input type="number" v-model="Miaosha.sale_num" required placeholder="请输入已秒杀数量" class="El_ii"></el-input>
                </div>
              </el-form-item>
              <el-form-item class="EditNewFormItemTube">
                <div class="EditNewFormItemName"><span class="signSpan">* </span>商品权重</div>
                <div class="EditNewFormItemIpt">
                  <el-input type="number" v-model="ruleFormRef.Row.goods_sort" required placeholder="请填写商品权重" class="El_ii"></el-input>
                </div>
              </el-form-item>
            </div>
          </div>
          <div class="EditNewForm" v-show="info_Show == 1">
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">PC封面图</div>
              <div class="EditNewFormItemIpt">
                <div class="Picture">
                  <el-upload
                    ref="upload-images"
                    list-type="picture-card"
                    :action="upload"
                    :headers="heads"
                    :limit="1"
                    :file-list="fileList"
                    :on-success="handleSuccess"
                    :on-exceed="handleExceed"
                    :on-preview="handlePreview">
                    <i class="el-icon-plus"></i>
                    <div class="elsff">上传图片</div>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">手机面图</div>
              <div class="EditNewFormItemIpt">
                <div class="Picture">
                  <el-upload
                    ref="upload-wap_image"
                    required
                    list-type="picture-card"
                    :action="upload"
                    :headers="heads"
                    :limit="1"
                    :file-list="fileList2"
                    :on-success="handleSuccess2"
                    :on-exceed="handleExceed2"
                    :on-preview="handlePreview">
                    <i class="el-icon-plus"></i>
                    <div class="elsff">上传图片</div>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">商品图标</div>
              <div class="EditNewFormItemIpt">
                <div class="Picture">
                  <el-upload
                    ref="upload-icon_image"
                    required
                    list-type="picture-card"
                    :action="upload"
                    :headers="heads"
                    :limit="1"
                    :file-list="fileList3"
                    :on-success="handleSuccess3"
                    :on-exceed="handleExceed3"
                    :on-preview="handlePreview">
                    <i class="el-icon-plus"></i>
                    <div class="elsff">上传图片</div>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">手机端服务介绍</div>
              <div class="EditNewFormItemIpt">
                <div id="demo1"></div>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">电脑端服务介绍</div>
              <div class="EditNewFormItemIpt">
                <div id="demo2"></div>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-show="info_Show == 2">
            <el-form-item class="EditNewFormItem" prop="Discount.vip">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>魔豆会员折扣</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number" v-model="ruleFormRef.Discount.vip" placeholder="请输入魔豆会员折扣"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem" prop="Discount.partner">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>魔豆合伙人折扣</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number" v-model="ruleFormRef.Discount.partner" placeholder="请输入魔豆合伙人折扣"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem" prop="Discount.month">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>企业月卡折扣</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number" v-model="ruleFormRef.Discount.month" placeholder="请输入企业月卡折扣"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem" prop="Discount.season">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>企业季卡折扣</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number" v-model="ruleFormRef.Discount.season" placeholder="请输入企业季卡折扣"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem" prop="Discount.year">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>企业年卡折扣</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number" v-model="ruleFormRef.Discount.year" placeholder="请输入企业年卡折扣"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem" prop="Discount.commission">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>魔豆合伙人返佣(百分制)</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number" v-model="ruleFormRef.Discount.commission" placeholder="请输入魔豆合伙人返佣(百分制)"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem" prop="Discount.code">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>推荐码折扣</div>
              <div class="EditNewFormItemIpt">
                <el-input type="number" v-model="ruleFormRef.Discount.code" placeholder="请输入推荐码折扣"></el-input>
              </div>
            </el-form-item>
          </div>
          
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="resetForm" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="!resetForm" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios'
import wangEditor from 'wangeditor'
export default {
  name: "index",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      miaoshaGoods:[],//列表数据
      miaototal:'', //列表条数
      LabelList:[],    //商品标签
      StatisticsColumns:[],  //获取统计列表
      CateList:[],     //商品分类
      
      Row_piii2:[],     //搜索商品分类选择
      acndex:1,  //弹出层选择
      fileList:[],  //pc图片
      fileList2:[],  //手机端图片
      fileList3:[],  //商品图标
      SiteList:[], //站点
      Spec_many:[],  //多规格表格数据
      Spec_many_title:[],  //多规格表头数据
      count_map :{},
      spec_ad:'',  //添加规格值
      Sku:0,       // 规格显示隐藏
      Sku_e:null,  // 规格显示隐藏
      Sku_d:0,  // 规格列表显示隐藏
      editor:'',
      editor2:'',
      wangE:0,
      editorData:'',   //富文本1
      editorData2:'',  //富文本2
      titleMath:'',
      editId: 0,
      info_Show:0, // 控制编辑新增
      ruleForm2: false,
      resetForm:'',
      // 列表搜索
      TypeList: [{id:10, name:'单规格'},{id:20, name:'多规格'}],
      Goods_staus: [{id:10, name:'上架'},{id:20, name:'下架'}],
      rules: { // 校验规则
        Row:{
          Row_piii: [{ required: true, message: '请选择', trigger: 'change' }],
          site_ids: [{  required: true, message: '请选择', trigger: 'change' }],
          goods_name: [{  required: true, message: '请输入', trigger: 'blur' }],
          goods_short_name: [{  required: true, message: '请输入', trigger: 'blur' }],
          goods_intro: [{  required: true, message: '请输入', trigger: 'blur' }],
          label_ids: [{  required: true, message: '请选择', trigger: 'change' }],
          spec_type: [{  required: true, message: '请选择', trigger: 'change' }],
          is_sale: [{  required: true, message: '请选择', trigger: 'change' }],
          sale_type: [{  required: true, message: '请选择', trigger: 'change' }],
          goods_status: [{  required: true, message: '请选择', trigger: 'change' }],
        },
        Discount:{
          vip: [{  required: true, message: '请输入', trigger: 'blur' }],
          partner: [{  required: true, message: '请输入', trigger: 'blur' }],
          month: [{  required: true, message: '请输入', trigger: 'blur' }],
          season: [{  required: true, message: '请输入', trigger: 'blur' }],
          year: [{  required: true, message: '请输入', trigger: 'blur' }],
          commission: [{  required: true, message: '请输入', trigger: 'blur' }],
          code: [{  required: true, message: '请输入', trigger: 'blur' }],
        },
        Spec:{
          goods_price: [{  required: true, message: '请输入', trigger: 'blur' }],
          line_price: [{  required: true, message: '请输入', trigger: 'blur' }],
          tuan_price: [{  required: true, message: '请输入', trigger: 'blur' }],
        }
      },
      SPEC:{
        spec_name:'',
        spec_value:''
      },
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      search: {    //搜索
        category_id: "",
        site_id: "",
        goods_name: "",
        spec_type: "",
        goods_status: "",
        keyword: "",
      },
      ruleFormRef:{
        Row: {
          Row_piii:[],     //商品分类选择
          category_id: 0, //商品分类ID
          goods_name: "", //商品名称
          goods_short_name: "", //商品简称
          goods_intro: "", //简介
          site_ids: [], //所属站点（多选）
          label_ids: [], //标签（多选）
          spec_type: '', //商品规格:10=单规格,20=多规格
          sale_type: '', //销售类型:10=立即购买,20=预定,30=报名,40=咨询
          is_sale: '', //是否在售:0=在售,1=已售完
          sales_initial: '', //初始销量
          goods_sort: '', //权重
          goods_status: '', //商品状态:10=上架,20=下架
          images: "", //商品图片
          wap_image: "", //手机端图片
          icon_image: "", //商品图标
          content: "", //手机端服务介绍
          content_pc: "", //电脑端服务介绍
        },
        Discount:{
          vip:'', 
          partner: '', 
          month: '',
          season: '', 
          year: '',
          commission: '', 
          code: ''
        },
        Spec:{ //单规格价格
          goods_price: '', //销售价
          line_price: '', //划线价
          tuan_price: '' //优惠价
        },
      },
      Miaosha:{  //秒杀
        total_num: '', //秒杀总库存
        start_time: "", //秒杀开始时间
        end_time: "", //秒杀结束时间
        min_buy_num: 1, //最低购买数量
        max_buy_num: '', //最多购买数量
        sale_num: 0, //已秒杀数量}, //秒杀
      },
      form:{ //多规格价格
        tuan_price:null,  //商品团购价
        goods_price:null, //商品销售价
        line_price:null  //商品划线价
      },
    }
  },
  mounted() {
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.loadTable()//列表数据
    that.labelList()//商品表情

    axios.get('/admin/ajax/siteList',{   // 站点列表    
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.SiteList = res.data.data
        }
    })   
    axios.get('/admin/goods/goods/statisticsColumns',{   // 上架列表    
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.StatisticsColumns = res.data.data
        }
    })   
    axios({ method: 'post', url: '/admin/ajax/cateList',   //商品分类
    }).then(function (res) {
        if (res.code == 401) {
          that.$router.push('/Login');
        }else {
          that.CateList = res.data.data
        }
    }) 
  },
  methods: {
    //获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'POST',
        url: '/admin/goods/activity/miaoshaGoods',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.miaoshaGoods = res.data.data.rows
        that.miaototal = res.data.data.total
      })
    },
    //商品标签
    labelList(){
        var that = this
        axios({ method: 'post', url: '/admin/ajax/labelList',       
        }).then(function (res) {
            if (res.code == 401) {
              that.$router.push('/Login');
            }else {
              that.LabelList = res.data.data.list
            }
        }) 
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if (e == false) {
        that.search = {
          category_id: "",
          site_id: "",
          goods_name: "",
          spec_type: "",
          goods_status: "",
          keyword: "",
          
        };
        that.Row_piii2=''
      }
      that.loadTable()
    },
    //打开创建按钮
    showAdd() {
      var that = this
      that.ruleForm2 = true;
      that.titleMath = '添加'
      that.resetForm = true
      that.info_Show = 0
      that.ruleFormRef.Row = {
        Row_piii : '',
        category_id: '', //商品分类ID
        goods_name: "", //商品名称
        goods_short_name: "", //商品简称
        goods_intro: "", //简介
        site_ids: [], //所属站点（多选）
        label_ids: [], //标签（多选）
        spec_type: '', //商品规格:10=单规格,20=多规格
        sale_type: '', //销售类型:10=立即购买,20=预定,30=报名,40=咨询
        is_sale: 1, //是否在售:0=在售,1=已售完
        sales_initial: '', //初始销量
        goods_sort: '', //权重
        goods_status: '', //商品状态:10=上架,20=下架
        images: "", //商品图片
        wap_image: "", //手机端图片
        icon_image: "", //商品图标
        content: "", //手机端服务介绍
        content_pc: "", //电脑端服务介绍
      };
      that.ruleFormRef.Spec = {
        goods_price: '', //销售价
        line_price: '', //划线价
        tuan_price: '' //优惠价
      }
      that.Miaosha = {
        total_num: 100, //秒杀总库存
        start_time: "", //秒杀开始时间
        end_time: "", //秒杀结束时间
        min_buy_num: 1, //最低购买数量
        max_buy_num: 1, //最多购买数量
        sale_num: '', //已秒杀数量}, //秒杀
      },
      that.ruleFormRef.Discount = {
        vip:'', 
        partner: '', 
        month: '',
        season: '', 
        year: '',
        commission: '', 
        code: ''
      },
      that.fileList = [];
      that.fileList2 = [];
      that.fileList3 = [];
    
    }, 
    // 创建
    BtnAdd() {
      var that = this
      let arr = {}
      that.ruleFormRef.Row.category_id = that.ruleFormRef.Row.Row_piii[1]
      let spec_attr = that.Spec_many_title
      let spec_list = that.Spec_many
      arr.spec_attr  = spec_attr
      arr.spec_list  = spec_list
      that.$refs['ruleFormRef'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'post',
            url: '/admin/goods/activity/miaoshaAdd',
            data: {
              row: {
                category_id : that.ruleFormRef.Row.category_id,
                goods_name : that.ruleFormRef.Row.goods_name,
                goods_short_name : that.ruleFormRef.Row.goods_short_name ,
                goods_intro : that.ruleFormRef.Row.goods_intro ,
                site_ids : that.ruleFormRef.Row.site_ids ,
                label_ids : that.ruleFormRef.Row.label_ids ,
                spec_type : that.ruleFormRef.Row.spec_type ,
                sale_type : that.ruleFormRef.Row.sale_type ,
                is_sale : that.ruleFormRef.Row.is_sale ,
                sales_initial : that.ruleFormRef.Row.sales_initial ,
                goods_sort : that.ruleFormRef.Row.goods_sort ,
                goods_status : that.ruleFormRef.Row.goods_status ,
                images : that.ruleFormRef.Row.images ,
                wap_image : that.ruleFormRef.Row.wap_image ,
                icon_image : that.ruleFormRef.Row.icon_image ,
                content : that.editorData ,
                content_pc : that.editorData2,
              },
              miaosha: that.Miaosha,
              spec:that.ruleFormRef.Spec,
              spec_many:arr,
              discount:that.ruleFormRef.Discount
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleForm2 = false
              that.loadTable();
            }
          }) 
        }
      })
    },
    //删除弹出框
    HandleDel(e, data) {
      var that = this
      var arr = []
      arr.push(data.goods_id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/goods/activity/miaoshaDel',
          data: {
            ids: arr
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    //新增编辑控制按钮
    handleSelect2(key, keyPath) {
      if (key == 1) {
        this.info_Show = 0;
      } else if(key == 2){
        this.info_Show = 1
        if (this.wangE == 20) {
          this.editor.destroy();
        } else {
          this.wangE = 0
        }
        this.init()
        this.init2()
      } else if(key == 3){
        this.info_Show = 2
      } 
    },
    // 获取编辑内容
    HandleEdit(e, data) {                        
      var that = this
      that.ruleFormRef.Row.Row_piii = []
      that.ruleForm2 = true;
      that.resetForm = false
      that.titleMath = '编辑'
      that.info_Show = 0
      that.editId = data.goods_id
      axios.get('/admin/goods/activity/miaoshaInfo', {  
        params: {
          id: data.goods_id
        }
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.ruleFormRef.Row.Row_piii = res.data.data.row.category_id
          that.ruleFormRef.Row.goods_name = res.data.data.row.goods_name
          that.ruleFormRef.Row.goods_short_name = res.data.data.row.goods_short_name
          that.ruleFormRef.Row.goods_intro = res.data.data.row.goods_intro
          that.ruleFormRef.Row.site_ids = res.data.data.row.site_ids
          that.ruleFormRef.Row.label_ids = res.data.data.row.label_ids
          that.ruleFormRef.Row.spec_type = res.data.data.row.spec_type
          that.ruleFormRef.Row.sale_type = res.data.data.row.sale_type
          that.ruleFormRef.Row.is_sale = parseInt(res.data.data.row.is_sale)
          that.ruleFormRef.Row.sales_initial = res.data.data.row.sales_initial
          that.ruleFormRef.Row.goods_sort = res.data.data.row.goods_sort
          that.ruleFormRef.Row.goods_status = res.data.data.row.goods_status
          that.editorData = res.data.data.row.content
          that.editorData2 = res.data.data.row.content_pc 
          that.ruleFormRef.Row.images = res.data.data.row.images
          that.ruleFormRef.Row.wap_image = res.data.data.row.wap_image
          that.ruleFormRef.Row.icon_image = res.data.data.row.icon_image
          // 单规格价格
          that.ruleFormRef.Spec = res.data.data.spec[0]  || []
          //多规格表格列表
          that.Spec_many = res.data.data.specData.spec_list || [];
          that.Spec_many_title = res.data.data.specData.spec_attr || [];
          //秒杀
          that.Miaosha = res.data.data.miaosha || []
          //折扣
          that.ruleFormRef.Discount = res.data.data.discount || []
           //图片
          let fileArr = [];
          let fileObj = {};
          fileObj.name = "file";
          fileObj.url = res.data.data.row.full_images;
          fileArr.push(fileObj);
          that.fileList = fileArr
           //图片
          let fileArr2 = [];
          let fileObj2 = {};
          fileObj2.name = "file";
          fileObj2.url = res.data.data.row.full_wap_image;
          fileArr2.push(fileObj2);
          that.fileList2 = fileArr2
           //图片
          let fileArr3 = [];
          let fileObj3 = {};
          fileObj3.name = "file";
          fileObj3.url = res.data.data.row.full_icon_image;
          fileArr3.push(fileObj3);
          that.fileList3 = fileArr3
          if(that.Spec_many != '' || that.Spec_many_title != ''){
            that.genSpecList()
            that.Sku = 3
          }
        }
      })
    },
    // 编辑确定按钮
    BtnEdit(e) {
      var that = this
      let arr = {} 
      let spec_attr = that.Spec_many_title
      let spec_list = that.Spec_many
      arr.spec_attr  = spec_attr
      arr.spec_list  = spec_list
      that.$refs['ruleFormRef'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'put',
            url: '/admin/goods/activity/miaoshaEdit',
            data: {
              id: that.editId,
              row: {
                category_id : that.ruleFormRef.Row.Row_piii,
                goods_name : that.ruleFormRef.Row.goods_name,
                goods_short_name : that.ruleFormRef.Row.goods_short_name ,
                goods_intro : that.ruleFormRef.Row.goods_intro ,
                site_ids : that.ruleFormRef.Row.site_ids ,
                label_ids : that.ruleFormRef.Row.label_ids ,
                spec_type : that.ruleFormRef.Row.spec_type ,
                sale_type : that.ruleFormRef.Row.sale_type ,
                is_sale : that.ruleFormRef.Row.is_sale ,
                sales_initial : that.ruleFormRef.Row.sales_initial ,
                goods_sort : that.ruleFormRef.Row.goods_sort ,
                goods_status : that.ruleFormRef.Row.goods_status ,
                images : that.ruleFormRef.Row.images ,
                wap_image : that.ruleFormRef.Row.wap_image ,
                icon_image : that.ruleFormRef.Row.icon_image ,
                content : that.editorData ,
                content_pc : that.editorData2,
              },
              spec:{
                goods_price : that.ruleFormRef.Spec.goods_price ,
                line_price : that.ruleFormRef.Spec.line_price ,
                tuan_price : that.ruleFormRef.Spec.tuan_price ,            
              },
              miaosha :{
                  total_num : that.Miaosha.total_num,
                  start_time : that.Miaosha.start_time,
                  end_time : that.Miaosha.end_time,
                  min_buy_num : that.Miaosha.min_buy_num,
                  max_buy_num : that.Miaosha.max_buy_num,
                  sale_num : that.Miaosha.sale_num,
              },
              spec_many:arr,
              discount : {
                vip : that.ruleFormRef.Discount.vip,
                partner : that.ruleFormRef.Discount.partner, 
                month : that.ruleFormRef.Discount.month,
                season : that.ruleFormRef.Discount.season, 
                year : that.ruleFormRef.Discount.year,
                commission : that.ruleFormRef.Discount.commission, 
                code : that.ruleFormRef.Discount.code,        
              }
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleForm2 = false
              that.loadTable();
            }
          })
        }
      })

    },
    handleChange(e) {       //商品分类
      var that = this
        var num = Number(that.ruleFormRef.Row.Row_piii.slice(-1))
        that.ruleFormRef.Row.category_id = num
    },
    handleChange2(e) {       // 搜索商品分类
      var that = this
      var num = Number(that.Row_piii2.slice(-1))
      that.search.category_id = num
    },
    // 分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    //关闭按钮
    Close() {
      var that = this
      that.ruleForm2 = false
      that.Spec_many_title = ''
      that.Spec_many = ''
      that.Sku = 0
      that.ruleFormRef.Row = {}
      that.ruleFormRef.Discount = {}
      that.Miaosha = {}
      that.ruleFormRef.Spec = {}
    },
    //选规格 
    Sku_Chang(e) {   
      var that = this
      if (e == 10) {  
        that.Sku = 0
      } else if(e == 20) {
        that.Sku = 1
      } else if (e == 'add') {
        if (that.SPEC.spec_value != '') {
          that.Sku_e = 'ad'
        } else {
          that.Sku = 4
        }
        that.SPEC.spec_name = ''
        that.SPEC.spec_value = ''
      } else if (e == 'sure') {
        that.Sku = 3
      }
    },
    //添加规格
    Sku_ADD(e) {    
      var that = this
      if (e == false) {
        that.Sku_e = ''
      }else {
        axios({ 
          method: 'post',
          url: '/admin/goods/goods/addSpec',    
          data: {
            spec_name: that.SPEC.spec_name,
            spec_value:that.SPEC.spec_value
          },
        }).then(function (res) {
          that.Sku = 3
          that.Sku_e = ''
          that.Spec_many_title.push({
            group_name: that.SPEC.spec_name,
            group_id: res.data.data.spec_id,
            spec_items: [{
              spec_value: that.SPEC.spec_value,
              item_id: res.data.data.spec_value_id,
            }]
          });
          that.Spec_many = that.genSpecList()
        }) 
      }
    },
    //添加规格值
    Sku_ADDSpace(e,data,index) {  
      var that = this
      var specId = data.group_id
      if(!e){
        return
      }else {
        axios({
          method: 'post',
          url: '/admin/goods/goods/addSpecValue',    
          data: {
            spec_id: specId,
            spec_value:e
          },
        }).then(function (res) {
          var spec_value_id = res.data.data.spec_value_id
          let arr = { item_id : spec_value_id,  spec_value : e }
          let currSku = that.Spec_many_title.find( item => item.group_id == specId)
          currSku.spec_items.push(arr)
          that.Spec_many = that.genSpecList()
          that.spec_ad = ''
        }) 
      }
    },
    //删除规格
    imgClick(id){
      var that = this
      let index = that.Spec_many_title.findIndex(obj => obj.index  === id)
      that.Spec_many_title.splice(index, 1)
      if(that.Spec_many_title.length == 0){
        that.Sku = 0
      }
      that.Spec_many = that.genSpecList()
    },
    // 合并行
    tableMerge({ row, column, rowIndex, columnIndex }){
      let tempIndex = columnIndex;
      let rows = row.rows;
      if(tempIndex < rows.length-1){
        let ids = rows.filter((item,index) => index <= tempIndex).map(( item ,index) => {
          return item.item_id;
        }).join("_")
        let spans = this.count_map[ids];
        //同层变量
        let stepArr = Object.keys(this.count_map).filter( item => item.length == ids.length);
        stepArr.sort( (a,b) => a>b ? 1 : -1)
        let currIndex = stepArr.findIndex( item => item == ids)
        let min = 0;
        if(currIndex != 0){
          min = stepArr.slice(0,currIndex).reduce( (total,item,index) => {
            return total + ( item==ids ? 0 : this.count_map[ids])
          },0)
        }
        if(min == rowIndex){
          return [spans,1]
        }else{
          return [0,0];
        }
      }else{
        return [1,1]
      }
    },
    // 递归
    genSpecList(){
			let result = [];
			let rows = this.Spec_many_title[0];
			rows.spec_items.forEach( item => {
				let src = {
					spec_sku_id : item.item_id + "",
					rows : [{
						item_id : item.item_id,
						spec_value : item.spec_value
					}],
					form : {
						goods_price : '',
						tuan_price : '',
						line_price : '',
					}
				};
				let tempArr = this.infiniteAssign(src);
				this.count_map[item.item_id + ""] = tempArr.length;
				result.push(...tempArr);
			})
			return result;
		},
    infiniteAssign(src,index = 1){
			let result = [];
			let rows = this.Spec_many_title[index];
			if(!rows){
				return [src];
			}
			let spec_items = rows.spec_items;
			spec_items.forEach( item => {
				let copeSrc = JSON.parse(JSON.stringify(src));
				let arr = copeSrc.spec_sku_id.split("_");
				arr.push(item.item_id);
				copeSrc.spec_sku_id = arr.join("_");
				copeSrc.rows.push({
					item_id : item.item_id,
					spec_value : item.spec_value
				})
				let tempArr = this.infiniteAssign(copeSrc,index+1);
				this.count_map[copeSrc.spec_sku_id] = tempArr.length;
				result.push(...tempArr);
			})
			return result;
		},
    init() {
      var that = this
      setTimeout(function() {
        var Eat = that.Eattr
          const editor = new wangEditor(`#demo1`)
          Eat = editor
        that.wangE = 20
        // 配置 onchange 回调函数，将数据同步到 vue 中
        // 下面两个配置，使用其中一个即可显示“上传图片”的tab。但是两者不要同时使用！！！
        editor.config.uploadImgHeaders = {
            token:that.heads.token
        }
        editor.config.onchange = (newHtml) => {
          that.editorData = newHtml
        }
        // editor.config.uploadImgShowBase64 = true   // 使用 base64 保存图片
        editor.config.showLinkImg = false  //关闭网络路径图片方式
        editor.config.uploadImgServer = 'https://rest-api.duxin365.com/admin/ajax/editorUpload'  // 上传图片到服务器
        editor.config.uploadFileName = 'file'
        editor.config.uploadImgTimeout = 50000;
        editor.config.uploadImgHooks = {
          // 图片上传并返回结果，但图片插入错误时触发
          fail: function (xhr, editor, result) {
            console.log(result)
          },
          success: function (xhr, editor, result) {
            // 图片上传并返回结果，图片插入成功之后触发
            console.log(result, 'success')
          }
        }

        editor.create()
        // 创建编辑器
        editor.txt.html(that.editorData) // 重新设置编辑器内容
        that.editor = editor
      },200)
    }, 
    init2() {
      var that = this
      setTimeout(function() {
        var Eat = that.Eattr
        const editor = new wangEditor(`#demo2`)
        Eat = editor
        that.wangE = 20
        // 配置 onchange 回调函数，将数据同步到 vue 中
        // 下面两个配置，使用其中一个即可显示“上传图片”的tab。但是两者不要同时使用！！！
        editor.config.uploadImgHeaders = {
          token:that.heads.token
        }
        editor.config.onchange = (newHtml) => {
          that.editorData2 = newHtml
        }
        // editor.config.uploadImgShowBase64 = true   // 使用 base64 保存图片
        editor.config.showLinkImg = false  //关闭网络路径图片方式
        editor.config.uploadImgServer = 'https://rest-api.duxin365.com/admin/ajax/editorUpload'  // 上传图片到服务器
        editor.config.uploadFileName = 'file'
        editor.config.uploadImgTimeout = 50000;
        editor.config.uploadImgHooks = {
          // 图片上传并返回结果，但图片插入错误时触发
          fail: function (xhr, editor, result) {
            console.log(result)
          },
          success: function (xhr, editor, result) {
            // 图片上传并返回结果，图片插入成功之后触发
            console.log(result, 'success')
          }
        }

        editor.create()
        // 创建编辑器

        editor.txt.html(that.editorData2) // 重新设置编辑器内容
        that.editor = editor
      },200)
    }, 
    //图片上传成功
    handleSuccess(e) {
      var that = this
      that.ruleFormRef.Row.images = e.data.url
    },
    handleSuccess2(e) {
      var that = this
      that.ruleFormRef.Row.wap_image = e.data.url
    },
    handleSuccess3(e) {
      var that = this
      that.ruleFormRef.Row.icon_image = e.data.url
    },

    handleExceed  (files)  {
      this.$refs["upload-images"].clearFiles()
      this.$refs["upload-images"].handleStart(files[0])
      this.$refs["upload-images"].submit();
    },
    handleExceed2  (files)  {
      this.$refs["upload-wap_image"].clearFiles()
      this.$refs["upload-wap_image"].handleStart(files[0])
      this.$refs["upload-wap_image"].submit();
    },
    handleExceed3  (files)  {
      this.$refs["upload-icon_image"].clearFiles()
      this.$refs["upload-icon_image"].handleStart(files[0])
      this.$refs["upload-icon_image"].submit();
    },
    handlePreview(file) {
      window.open(file.url);
    },
    //图片上传成功
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d
    },
  }
}
</script>
<style scoped>
.seckill_i .Rig_D{
  margin-bottom: 0px;
}
</style>